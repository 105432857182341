<!-- <template>
  <div class="mobile-menu" :class="{open: $store.state.ui.menu.open}">

    <div class="main-menu">
      <template v-for="(link,i) in $store.state.ui.menu.main" :key="i">

  
        <router-link class="link" v-if="link.route" :to="link.route">
          <div class="label">{{ $t(link.label) }}</div>
        </router-link>

       
        <a class="link" v-if="link.url" :href="$t(link.url)">
          <div class="label">{{ $t(link.label) }}</div>
        </a>

      </template>
    </div>

    <div class="secondary-menu">
      <template v-for="(link,i) in $store.state.ui.menu.secondary" :key="i">

      
        <router-link class="link" v-if="link.route" :to="link.route">

          <div class="label">{{ $t(link.label) }}</div>
        </router-link>

    
        <a class="link" v-if="link.url" :href="$t(link.url)">

          <div class="label">{{ $t(link.label) }}</div>
        </a>

      </template>
      <div class="link lang-picker" @click="$store.dispatch('openLanguagePicker')">
        <div class="label">{{ $t('menu.secondary.languagePicker.label') }}</div>
        <LanguagePicker no-popup/>
      </div>
    </div>

  </div>
</template>
-->
<script>
import LanguagePicker from "@/components/core/LanguagePicker.vue";

export default {
  name: "MobileMenu",
  components: {
    LanguagePicker,
  },
  created() {
    document.addEventListener("keydown", this.keyboardHandler);
  },
  unmounted() {
    document.removeEventListener("keydown", this.keyboardHandler);
  },
  methods: {
    keyboardHandler(e) {
      if (e.key === "Escape") {
        this.$store.commit("closeMenu");
      }
    },
  },
};
</script>

<style lang="css" scoped>
.mobile-menu {
  background-color: white;
  position: fixed;
  top: var(--header-height);
  left: 0;
  bottom: 0;
  right: 0;
  transition: 0.2s all ease-out;
  transform: translateX(100vw);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  padding-top: 30px;
  border-top: 1px solid #f9f9f9;
  z-index: 100;
  touch-action: none;
}
.open {
  transform: none;
}

.site-search {
  margin-bottom: 30px;
}
.main-menu {
  margin-bottom: 30px;
}

.link {
  cursor: default;
}
.link:hover {
  color: #f0f;
}
</style>
