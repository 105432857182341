import { createApp, h } from 'vue'
import { createHead } from '@vueuse/head'
import App from '@/App.vue'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import router from '@/router/index.js'
import store from '@/store/index.js'
import i18n from '@/i18n/index.js'
import VueGtag from 'vue-gtag'

const app = createApp({
  render: () => h(App)
})
app.use(store)
app.use(router)
window.i18n = i18n
app.use(i18n)
app.use(createHead())
app.use(VueGoogleMaps, {
  load: {
    key: store.state.system.GOOGLE_MAPS_API_KEY,
    libraries: 'places'
  }
})
app.use(VueGtag, { config: { id: 'G-VLCRT3CG12' } })
app.use(VueReCaptcha, { siteKey: store.state.system.RECAPTCHA_SITE_KEY })

app.mount('#app')

window.app = app
