import types from '@/store/types.js'
import api from '@/services/profile.js'
import { indexArray } from '@/utils.js'

const getters = {
  profiles: (state) => state.profiles,
  profile: (state) => ({ id, store }) => {
    // loaded
    if (state.profiles[id]) {
      return state.profiles[id]
    }

    // console.log(`Requested profile ${id} was not loaded - fetching from api`)
    store.dispatch('loadProfile', id)

    // 404 Not Found
    if (state.profiles[id] === false) {
      return false
    }

    // loading
    return null
  },
  localeLabel: (state) => (locale) => {
    if (locale === 'sv') {
      return 'SE'
    }
    if (locale === 'nb') {
      return 'NO'
    }
    return locale.toUpperCase()
  }
}

const actions = {
  loadProfile (store, id) {
    // console.log('loading profile', id)
    return new Promise((resolve, reject) => {
      api.getProfile(id).then(profile => {
        if (profile) {
          store.dispatch('cache', { key: 'profiles', id, data: profile })
          console.log('done cache', profile, 'save to store now')
          store.commit(types.mutations.SET_PROFILE, { id, profile })
          console.log('200?', profile)
        } else {
          console.log('404')
          store.commit(types.mutations.SET_PROFILE, { id, data: false })
        }
        resolve(profile)
      }).catch((ex) => {
        console.log('rej')
        reject(ex)
      })
    })
  },

  loadAllProfiles (store) {
    return new Promise((resolve, reject) => {
      api.getAllProfiles().then(profilesArray => {
        if (!profilesArray) {
          profilesArray = []
        }
        const profiles = indexArray(profilesArray, 'id')
        store.dispatch('cacheAll', { key: 'profiles', data: profiles })
        store.commit(types.mutations.SET_PROFILES, profiles)
        resolve(profiles)
      }).catch((ex) => {
        reject(ex)
      })
    })
  },

  async submitContactForm (store, payload) {
    return await api.submitContactForm(payload)
  }

}

const mutations = {
  [types.mutations.SET_PROFILE] (state, { id, profile }) {
    // console.log('mut setprofile', id, profile)

    state.profiles[id] = profile
  },
  [types.mutations.SET_PROFILES] (state, profiles) {
    state.profiles = profiles
  },
  [types.mutations.LOAD_CACHED_PROFILES] (state) {
    const cached = JSON.parse(window.localStorage.getItem('profiles')) || {}
    state.profiles = cached
  }
}

export default {
  getters,
  actions,
  mutations,
  state: {
    profiles: {
    }
  }

}
