<template>
    <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12"><g transform="translate(-19069 11084)"><rect width="20" height="3" transform="translate(19069 -11084)"/><rect width="20" height="3" transform="translate(19069 -11075)"/></g></svg>
</template>

<script>
export default {
  name: 'Hamburger'
}
</script>

<style lang="css" scoped>
</style>
