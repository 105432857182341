<template>
    <div class="btn">
        <Icon class="icon" :icon="icon"/>
    </div>
</template>

<script>
// import IconButton from "@/components/core/buttons/IconButton.vue"
import Icon from '@/components/core/Icon.vue'
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      default: 'search'
    }
  },
  components: {
    Icon
  },
  computed: {
    classes () {
      return {}
    }
  }
}
</script>

<style lang="css" scoped>
.btn {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(78,102,102,.04);
    border-radius: 100%;
    cursor: pointer;
    transition: all 0.2s;
}
.icon {
    width: 24px;
    height: 24px;
}

.btn:hover {
    background-color: rgba(78,102,102,.08);
}
.btn:active {
    background-color: rgba(78,102,102,.12);
}
</style>
