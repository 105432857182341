import client from '@/plugins/axios.js'

function formatProfile (raw) {
  const profile = raw
  const nameParts = raw.name.split(' ')

  profile.name = {
    full: raw.name,
    first: nameParts[0],
    last: nameParts[nameParts.length - 1]
  }

  profile.id = profile.dealernumber

  if (!profile.level) {
    profile.level = 0
  }

  if (typeof profile.address.addressLine1 === 'undefined') {
    profile.location = {
      position: {
        lat: parseFloat(profile.geoLoc.latitude),
        lng: parseFloat(profile.geoLoc.longitude)
      },
      address: `${profile.address.postCode}, ${profile.address.postLocation}`,
      zip: profile.address.postCode
    }
  } else {
    profile.location = {
      position: {
        lat: parseFloat(profile.geoLoc.latitude),
        lng: parseFloat(profile.geoLoc.longitude)
      },
      address: `${profile.address.addressLine1}, ${profile.address.postLocation}`,
      zip: profile.address.postCode
    }
  }

  return profile
}

export default {
  getProfile (id) {
    return new Promise((resolve, reject) => {
      client().get('/profile/dealer/' + id)
        .then(res => {
          const profile = formatProfile(res.data.data)
          resolve(profile)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getAllProfiles (id) {
    return new Promise((resolve, reject) => {
      client().get('/profile/all')
        .then(res => {
          const profiles = res.data.data.map(profile => {
            return formatProfile(profile)
          })
          resolve(profiles)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  submitContactForm (payload) {
    const result = {
      status: true,
      message: 'profile.contactForm.submissionResults.success'
    }
    return new Promise(resolve => {
      client().post('/profile/contactForm', payload)
        .then(res => {
          // TODO: remove debug logging
          // console.log('res', res)
          if (res.data.statusCode !== 200) {
            result.status = false
            result.message = res.data.statusMessage
            result.messageParams = res.data.statusMessageParams || []
          }
        })
        .catch(() => {
          result.status = false
          result.message = 'profile.contactForm.submissionResults.connectionError'
        })
        .finally(() => {
          resolve(result)
        })
    })
  }

}
