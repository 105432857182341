<template>
    <svg class="location-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="32.132" height="32.132" viewBox="0 0 32.132 32.132">
  <path d="M0,0H32.132V32.132H0Z" fill="none"/>
  <path d="M15.727,10.372a5.355,5.355,0,1,0,5.355,5.355A5.354,5.354,0,0,0,15.727,10.372ZM27.7,14.388a12.042,12.042,0,0,0-10.63-10.63V2.339a1.339,1.339,0,1,0-2.678,0V3.758a12.042,12.042,0,0,0-10.63,10.63H2.339a1.339,1.339,0,1,0,0,2.678H3.758A12.042,12.042,0,0,0,14.388,27.7v1.419a1.339,1.339,0,1,0,2.678,0V27.7A12.042,12.042,0,0,0,27.7,17.066h1.419a1.339,1.339,0,1,0,0-2.678H27.7ZM15.727,25.1A9.372,9.372,0,1,1,25.1,15.727,9.365,9.365,0,0,1,15.727,25.1Z" transform="translate(0.339 0.339)" fill="currentColor"/>
</svg>

</template>

<script>
export default {
  name: 'Location'
}
</script>

<style scoped>
.pin-icon {
  transition: fill 0.22s ease-out;
}
</style>
