<template>
  <label class="radio" :class="classes">
    <input type="radio" :name="name" @change="input" v-show="false" :id="labelId">
    <svg :width="size" :height="size" :viewBox="`0 0 ${size} ${size}`">
      <circle class="outer" stroke-width="2" :cx="size/2" :cy="size/2" :r="size/2"/>
      <circle class="inner" stroke-width="0" :cx="size/2" :cy="size/2" :r="innerSize"/>
    </svg>
    <span class="debug" v-if="debug">
      <pre>{{classes}}</pre>
      <pre>model: {{modelValue}}</pre>
      <pre>value: {{value}}</pre>
    </span>
  </label>

</template>

<script>
// import RadioButton from "@/components/forms/RadioButton.vue"
// <RadioButton debug :value="locale" v-model="selectedLanguage" name="language" size="32"/>

export default {
  name: 'RadioButton',
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: String, required: true },
    value: { type: String, required: true },
    name: { type: String, required: true },
    size: { type: [String, Number], default: 32 },
    disabled: Boolean,
    'label-id': String,
    debug: Boolean
  },
  computed: {
    classes () {
      return {
        on: this.modelValue === this.value,
        off: this.modelValue !== this.value,
        disabled: this.disabled
      }
    },
    innerSize () {
      return this.size * 0.3125
    }
  },
  methods: {
    input (e) {
      if (e.target.checked) {
        this.$emit('update:modelValue', this.value)
      }
    }
  }
}
</script>

<style lang="css" scoped>
svg {
    overflow: visible;
}
.outer {
  transform-origin: center;
  transition: all 0.4s ease-in;
  stroke: #7E9495;
  fill: #F7F9F9;
}
.inner {
  transform-origin: center;
  transition: all 0.3s ease-in;
  scale: 0.6;
  fill: transparent;
}

.on .outer {
  stroke: #435152;
  fill: #ffffff;
  transition-timing-function: ease-out;
}
.on .inner {
  scale: 1;
  fill: #435152;
  transition-timing-function: ease-out;
}
</style>
