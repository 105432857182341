<template>
  <div id="dialog-container">
    <!-- Teleport target -->
  </div>
</template>

<script>
export default {
  name: 'DialogContainer'

}
</script>

<style lang="css">
#dialog-container {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 20000;
}
</style>
