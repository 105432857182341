<template>
  <div class="language-dialog">
    <h2>{{ $t('lang.picker.title') }}</h2>
    <table class="locale-list">
      <thead>
        <tr>
          <th></th>
          <th>{{ $t('lang.picker.country') }}</th>
          <th>{{ $t('lang.picker.currency') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="({locale, label, currency}, i) in locales" :key="`locale-${i}`">
          <td>
            <label class="fill-cell" :for="`lang-picker-${i}`">
              <RadioButton :value="locale" v-model="selectedLanguage" name="language" size="17" :label-id="`lang-picker-${i}`"/>
            </label>
          </td>
          <td class="locale-label">
            <label class="fill-cell" :for="`lang-picker-${i}`">{{ label }}</label>
          </td>
          <td class="currency">
            <label class="fill-cell" :for="`lang-picker-${i}`">{{ currency }}</label>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
 import RadioButton from '@/components/forms/RadioButton.vue'

 export default {
     name: 'LanguageDialog',
     data () {
         return {
             selectedLanguage: this.$i18n.locale
         }
     },
     components: { RadioButton },
     computed: {
         locales () {
             const locales = [
                 { locale: 'nb', label: this.$t('lang.nb.label'), currency: this.$t('lang.nb.currency') },
                 { locale: 'sv', label: this.$t('lang.sv.label'), currency: this.$t('lang.sv.currency') },
                 { locale: 'fi', label: this.$t('lang.fi.label'), currency: this.$t('lang.fi.currency') }
             ]
             return locales
         }
     },
     watch: {
         selectedLanguage: {
             handler: function (locale) {
               this.$store.commit('changeLocale', { locale, $i18n: this.$i18n })
               this.$router.go(0)
             }
         }
     }
 }
</script>

<style lang="css" scoped>
h2 {
  font-size: 48px;
  margin-bottom: 8px;
  font-weight: 500;
  letter-spacing: -0.025em;
  line-height: 1.1;
  text-align: center;
  color: #353535;
  max-width: 67%;
  margin-left: auto;
  margin-right: auto;
}
.locale-list {
  margin-top: 20px;
  margin-bottom: 10px;
}
td, th {
  padding: 12px;
}
th {
  text-transform: uppercase;
  vertical-align: bottom;
  border-bottom: 2px solid #f9f9f9;
  font-size: 11px;
}
td {
  vertical-align: middle;
  line-height: 1;
}

.language-dialog {
  min-width: 67vw;
  max-width: 770px;
}
table {
  margin-left: auto;
  margin-right: auto;
}

.currency {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 1.625;
}

.fill-cell {
  padding: 12px;
  margin: -12px;
  display: flex;
}
tbody tr {
  transition: background-color 0.2s;
}
tbody tr:hover {
  background-color: #F8F8F8;
}
</style>
