<template>
  <svg style="fill: currentColor" xmlns="http://www.w3.org/2000/svg" width="32.021" height="32.021" viewBox="0 0 32.021 32.021">
    <path id="Path_1729" data-name="Path 1729" d="M0,0H32.021V32.021H0Z" fill="none"/>
    <path id="Path_1730" data-name="Path 1730" d="M21.381,19.172H20.22l-.412-.4A9.586,9.586,0,1,0,18.778,19.8l.4.412v1.161l6.25,6.248a1.549,1.549,0,0,0,2.191-2.191Zm-8.823,0a6.616,6.616,0,1,1,6.617-6.616A6.608,6.608,0,0,1,12.558,19.172Z" transform="translate(0 0)" fill="#435152"/>
  </svg>
</template>

<script>
export default {
  name: 'Search'
}
</script>

<style lang="css" scoped>
svg {
  transition: fill 0.2s;
}
</style>
