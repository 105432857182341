import axios from 'axios'
export default ({ requiresAuth = false, fileUpload = false } = {}) => {
  let headers = {
    Authorization: null
  }
  if (fileUpload) {
    headers = {
      Authorization: null,
      'Content-Type': 'multipart/form-data'
    }
  }

  const options = {
    headers: headers
  }

  options.baseURL = getApiUrl()
  console.log('Profile using base url ' + options.baseURL)

  const instance = axios.create(options)
  instance.interceptors.response.use(response => {
    return response
  }, error => {
    if (error.response) {
      return Promise.reject(error.response.data.message)
    }
    return Promise.reject(error.message)
  })

  return instance
}

function getApiUrl () {
  const fromEnv = process.env.VUE_APP_API_URL

  if (fromEnv) {
    console.log(`%c API baseURL found from env: ${fromEnv} `, 'background: #00b62f; color: green; font-weight: bold;')
    return fromEnv
  }

  // Fall back to hardcoded API urls if no env variables found:
  const fallbacks = {
    production: 'https://api.perfecthome.com',
    development: 'https://apidev.perfecthome.com'
  }

  console.log(`%c VUE_APP_API_URL not found in env: ${fromEnv} `, 'background: #650000; color: red; font-weight: bold;')
  if (window.location.hostname === 'profile.perfecthome.com') {
    console.log(`%c↳ Using [PROD] fallback ${fallbacks.production} `, 'color: red;font-weight: bold;')
    return fallbacks.production
  }
  console.log(`%c↳ Using [DEV] fallback ${fallbacks.development} `, 'color: red;font-weight: bold;')

  return fallbacks.development
}
