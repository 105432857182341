<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1034.367" height="950.95" viewBox="0 0 1034.367 950.95">
  <path fill="currentColor" d="M517.183,1001c159.065,0,280.836-120.378,280.836-280.836,0-67.74-19.834-117.822-62.567-184.919-31.639-49.678-37.634-60.047-43.946-79.347a88.108,88.108,0,0,1-4.709-27.692c5.947-4.633,15.8-10.884,29.407-17.644,15.276-7.59,97.264-40.845,118.568-50.606,56.687-25.97,99.461-53.717,133.5-90.773,42.433-46.19,66.091-105.486,66.091-174.646A44.492,44.492,0,0,0,989.878,50.05H44.487A44.492,44.492,0,0,0,0,94.539C0,163.7,23.658,222.993,66.091,269.183c34.043,37.056,76.817,64.8,133.5,90.773,21.3,9.76,103.292,43.016,118.568,50.605,13.6,6.76,23.46,13.011,27.849,17.347,1.559,9.309,0,18.356-3.151,27.989-6.312,19.3-12.307,29.669-43.945,79.347-42.734,67.1-62.567,117.179-62.567,184.919C236.347,880.622,358.118,1001,517.183,1001ZM902.864,209.246c-23.879,25.993-58.067,47.835-105.45,69.543-6.365,2.892-6.365,2.892-12.76,5.712-15.042,6.591-72.378,30.16-64.3,26.789-17.665,7.372-31.061,13.332-43.326,19.426-23.1,11.477-38.2,21.048-51.224,33.92-17.29,17.084-27.984,39.785-27.984,63.569,0,46.893,14.556,80.587,50.837,136.757,5.966,9.206,8.972,13.853,11.73,18.184,35.392,55.57,48.655,88.7,48.655,137.017,0,115.043-77.879,191.858-191.858,191.858S325.325,835.207,325.325,720.164c0-48.317,13.263-81.447,48.655-137.017,2.759-4.331,5.765-8.978,11.73-18.184,36.281-56.171,50.837-89.864,50.837-136.757,0-23.784-10.694-46.485-27.985-63.569-13.028-12.871-28.127-22.442-51.223-33.919-12.265-6.094-25.661-12.055-43.326-19.426,8.078,3.371-49.259-20.2-64.3-26.789-6.4-2.821-6.4-2.821-12.76-5.712-47.383-21.709-81.572-43.55-105.451-69.543a150.148,150.148,0,0,1-36.778-70.218H939.642A150.148,150.148,0,0,1,902.864,209.246Z" transform="translate(1034.367 1001) rotate(180)"/>
</svg>

</template>

<script>
export default {
  name: 'UserOutline'
}
</script>

<style lang="css" scoped>
svg {
  transition: fill 0.2s;
}
</style>
