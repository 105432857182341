
export default {
  geoCode (searchString, mapRef) {
    return new Promise((resolve, reject) => {
      const request = {
        query: searchString,
        types: [''],
        fields: ['name', 'geometry']
      }
      mapRef.textSearch(request, (results) => {
        resolve(results)
      })
    })
  }
}
