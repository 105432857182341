<template>
  <div class="app" :class="classes">
    <Head>
      <html :lang="localeLabel" />
    </Head>
    <router-view />
    <MobileMenu />
    <DialogContainer />
    <Notifications />
  </div>
</template>

<script>
import "@/css/reset.css";
import MobileMenu from "@/components/layout/MobileMenu.vue";
import { Head } from "@vueuse/head";
import DialogContainer from "@/components/dialogs/DialogContainer.vue";
import Notifications from "@/components/notifications/Notifications.vue";

export default {
  name: "App",
  computed: {
    classes() {
      return {
        "disable-scroll": this.$store.state.ui.menu.open,
      };
    },
    localeLabel() {
      const label =
        this.$store.getters.localeLabel(this.$i18n?.locale ?? "nb") || "nb";
      return label.toLowerCase();
    },
  },
  components: {
    Head,
    MobileMenu,
    DialogContainer,
    Notifications,
  },
  beforeCreate() {
    // this.$store.dispatch('loadAllCachedData')
  },
  beforeRouteEnter(to, from) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    if (to.query.lang) {
      console.log("with lang:" + to.query.lang);
    } else {
      console.log("enter");
    }
  },

  mounted() {
    const locale = window.localStorage.getItem("locale");
    console.log(
      locale +
        " " +
        this.$i18n.locale +
        " to " +
        JSON.stringify(this.$route.params)
    );
    if (this.$i18n.locale !== locale) {
      console.log("not equal");
      this.$store.commit("changeLocale", { locale, $i18n: this.$i18n });
      this.$router.go(0);
    }
  },
};
</script>

<style lang="css">
@font-face {
  font-family: "Rando";
  font-weight: normal;
  font-style: normal;
  src: local("Rando"), url(./fonts/Rando-SemiBold.woff2) format("woff2");
}
@font-face {
  font-family: "Circular Std";
  font-weight: normal;
  font-style: normal;
  src: url(./fonts/CircularStd-Book.woff2) format("woff2");
}
@font-face {
  font-family: "Circular Std";
  font-weight: 500;
  font-style: normal;
  src: url(./fonts/CircularStd-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "Circular Std";
  font-weight: bold;
  font-style: normal;
  src: url(./fonts/CircularStd-Bold.woff2) format("woff2");
}

pre {
  font-family: monospace;
  white-space: pre-wrap;
  font-size: 16px;
}
:root {
  color: #435152;
  --max-width: 1210px;
  --header-height: 68px;
  --gutter: 16px;
  --margin-auto-size: max(0px, calc(calc(100vw - var(--max-width)) / 2));
}

.debug {
  backdrop-filter: saturate(1.8) blur(16px);
  background: rgba(255, 255, 255, 0.85);
  z-index: 999;
  display: flex;
  flex-direction: column;
  position: absolute;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.debug > * {
  padding: 1em;
}

.app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  font-family: "Circular Std";
  font-weight: normal;
  font-size: 16px;
}
.core-page {
  margin-left: auto;
  margin-right: auto;
}
.layout-container {
  max-width: var(--max-width);
  margin-right: auto;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

h1 {
  color: #f7f9f9;
  font-family: Rando;
  font-size: 32px;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .core-page {
    margin-top: var(--header-height);
  }
  .compact-header {
    position: fixed;
    top: 0;
  }
  .app {
    height: auto;
  }
}
.mb20 {
  margin-bottom: 20px;
}

.ph-caption {
  text-transform: uppercase;
  font-size: 11px;
  color: rgba(53, 53, 53, 0.75);
  line-height: 1.625;
  letter-spacing: 1px;
  margin-bottom: 9px;
}

.grecaptcha-badge {
  visibility: hidden;
}
</style>

<style lang="css" scoped>
.disable-scroll {
  overflow: hidden;
}
</style>
