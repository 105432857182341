<template>
  <svg class="pin-icon" style="fill: currentColor;" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0,0H24V24H0Z" fill="none"/><path d="M12,12a2,2,0,1,0-2-2A2.006,2.006,0,0,0,12,12ZM12,2a7.955,7.955,0,0,1,8,8.2q0,4.77-7.34,11.23a1.017,1.017,0,0,1-1.33,0Q4.01,14.965,4,10.2A7.955,7.955,0,0,1,12,2Z"/></svg>
</template>

<script>
export default {
  name: 'Pin'
}
</script>

<style scoped>
.pin-icon {
  transition: fill 0.20s ease-out;
}
</style>
