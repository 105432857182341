<template>
  <svg style="fill: currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle cx="12" cy="12" r="12" transform="translate(0 0)" fill="#435152"/><path d="M44.611,29.655h-2.1v7.923H39.326V29.655H37.812V26.871h1.514v-1.8a3.037,3.037,0,0,1,3.212-3.306l2.358.01v2.7H43.185c-.281,0-.675.144-.675.759v1.639h2.379Z" transform="translate(-29.658 -17.195)" fill="#fff"/></svg>
</template>

<script>
export default {
  name: 'Facebook'
}
</script>

<style lang="css" scoped>
svg {
  transition: fill 0.2s;
}
</style>
