export default {
  state: {
    menu: {
      main: [
        { label: 'menu.main.products.label', url: 'menu.main.products.url' },
        { label: 'menu.main.offers.label', url: 'menu.main.offers.url' },
        { label: 'menu.main.inspiration.label', url: 'menu.main.inspiration.url' }
      ],
      secondary: [
        {
          label: 'menu.secondary.findDealer.label',
          route: { name: 'FindDealer', params: { searchMode: 'location' } },
          icon: 'user-outline'
        },
        { label: 'menu.secondary.becomeDealer.label', url: 'menu.secondary.becomeDealer.url', icon: 'user-outline' },
        { label: 'menu.secondary.becomeCustomer.label', url: 'menu.secondary.becomeCustomer.url', icon: 'user-outline' }
      ]
    },
    open: false
  },
  getters: {

  },
  actions: {

  },
  mutations: {
    toggleMenu (state) {
      state.menu.open = !state.menu.open
    },
    openMenu (state) {
      state.menu.open = true
    },
    closeMenu (state) {
      state.menu.open = false
    }
  }
}
