import { createStore } from 'vuex'

import ui from '@/store/ui.js'
import profiles from '@/store/profiles.js'
import system from '@/store/system.js'
import geolocations from '@/store/geolocations.js'

export default createStore({
  strict: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ui,
    profiles,
    system,
    geolocations
  }
})
