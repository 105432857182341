<template>
  <svg style="fill: currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M211.679,209.34a2.34,2.34,0,1,1-2.34-2.34A2.34,2.34,0,0,1,211.679,209.34Zm0,0" transform="translate(-197.34 -197.34)" fill="#435152"/><path d="M146.711,137.1a2.324,2.324,0,0,0-1.332-1.332,3.883,3.883,0,0,0-1.3-.242c-.74-.034-.962-.041-2.836-.041s-2.1.007-2.836.041a3.885,3.885,0,0,0-1.3.242,2.325,2.325,0,0,0-1.332,1.332,3.885,3.885,0,0,0-.242,1.3c-.034.74-.041.962-.041,2.836s.007,2.1.041,2.836a3.883,3.883,0,0,0,.242,1.3,2.324,2.324,0,0,0,1.332,1.332,3.879,3.879,0,0,0,1.3.242c.74.034.962.041,2.836.041s2.1-.007,2.836-.041a3.879,3.879,0,0,0,1.3-.242,2.324,2.324,0,0,0,1.332-1.332,3.886,3.886,0,0,0,.242-1.3c.034-.74.041-.962.041-2.836s-.007-2.1-.041-2.836A3.878,3.878,0,0,0,146.711,137.1Zm-5.472,7.744a3.6,3.6,0,1,1,3.6-3.6A3.6,3.6,0,0,1,141.239,144.847Zm3.747-6.509a.842.842,0,1,1,.842-.842A.842.842,0,0,1,144.986,138.338Zm0,0" transform="translate(-129.239 -129.243)" fill="#435152"/><path d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm6.849,14.841a5.056,5.056,0,0,1-.32,1.673,3.523,3.523,0,0,1-2.015,2.015,5.059,5.059,0,0,1-1.672.32c-.735.034-.97.042-2.841.042s-2.106-.008-2.841-.042a5.059,5.059,0,0,1-1.672-.32,3.523,3.523,0,0,1-2.015-2.015,5.054,5.054,0,0,1-.32-1.672c-.034-.735-.042-.97-.042-2.841s.008-2.106.042-2.841a5.056,5.056,0,0,1,.32-1.673A3.526,3.526,0,0,1,7.486,5.471a5.06,5.06,0,0,1,1.673-.32c.735-.034.97-.042,2.841-.042s2.106.008,2.841.042a5.061,5.061,0,0,1,1.673.32,3.524,3.524,0,0,1,2.015,2.015,5.055,5.055,0,0,1,.32,1.673c.034.735.041.97.041,2.841S18.883,14.106,18.849,14.841Zm0,0" fill="#435152"/></svg>
</template>

<script>
export default {
  name: 'Instagram'
}
</script>

<style lang="css" scoped>
svg {
    transition: fill 0.2s;
}
</style>
