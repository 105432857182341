
import types from '@/store/types.js'
import client from '@/services/google-maps.js'

export default {
  state: {
    lastSearch: false,
    searchString: '',
    lastResults: [],
    throttle: 0
  },
  getters: {
    lastResults: (state) => state.lastResults
  },
  actions: {
    searchForLocation (store, payload) {
      return new Promise((resolve, reject) => {
        const results = []
        client.geoCode(payload.search, payload.service).then((res) => {
          if (res === null) {
            resolve(results)
          }
          res.map((el) => {
            results.push({
              place_id: el.place_id,
              name: el.name,
              address: el.formatted_address,
              location: { lat: el.geometry.location.lat(), lng: el.geometry.location.lng() },
              viewport: el.geometry.viewport
            })
           })

          store.commit(types.mutations.SET_LAST_GEOCODE_RESULTS, results)
          resolve(results)
        }).catch((e) => {
          reject(e)
        })
      })
    }
  },
  mutations: {
    [types.mutations.SET_LAST_GEOCODE_RESULTS] (state, results) {
      state.lastResults = results
    }
  }
}
