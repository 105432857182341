import types from '@/store/types.js'

export default {
  state: {
    // Done: Register a production key here: https://www.google.com/recaptcha/admin/create
    // Martin: RECAPTCHA_SITE_KEY: '6LdvIrAeAAAAAFuJRhbT1rsKg9WuSfUv9t9Qr8L0', // TEST KEY
    // Martin: RECAPTCHA_SECRET_KEY_REMOVE_THIS_LATER: '6LdvIrAeAAAAADszZm6StWbulFtO7es-Dt2Ib4cm',
    // TODO: Secret key is not needed here, Remove from store once final keys are generated

    RECAPTCHA_SITE_KEY: '6LeOb7MeAAAAAADTZumgbkdLU95QJOPCiXd8m7lP',
    RECAPTCHA_SECRET_KEY_REMOVE_THIS_LATER: '6LeOb7MeAAAAABsnjLbY-PbDzO7_J09TEFXy4ic0',

    // ErikmodeGOOGLE_MAPS_API_KEY: 'AIzaSyDs3irLbgSLtfgQ_SxkJl9e16iS666U7ic'
    GOOGLE_MAPS_API_KEY: 'AIzaSyDVDKpN6g-VXsiHVEjvzARVOLDo-hoL_Jg'
  },
  getters: {
    googleApiKey: (state) => state.GOOGLE_MAPS_API_KEY
  },
  actions: {
    cache (store, { key, id, data }) {
      const all = JSON.parse(window.localStorage.getItem(key)) || {}
      all[id] = data
      window.localStorage.setItem(key, JSON.stringify(all))
    },
    cacheAll (store, { key, data }) {
      window.localStorage.setItem(key, JSON.stringify(data))
    },
    loadAllCachedData (store) {
      store.commit(types.mutations.LOAD_CACHED_PROFILES)
    },
    notification () { /* handled in Notifications.vue */ },
    openLanguagePicker () { /* handled in LanguagePicker.vue */ }
  },
  mutations: {
    changeLocale (state, { locale, $i18n }) {
      $i18n.locale = locale
      window.localStorage.setItem('locale', locale)
    }
  }
}
