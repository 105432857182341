// import { hexToRGB } from '@/utils.js'

function hexToRGB (hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha !== undefined) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  return `rgb(${r}, ${g}, ${b})`
}

// text-white -> textWhite
function kebabToCamelCase (s) {
  return s.replace(/-./g, x => x[1].toUpperCase())
}

// [5,10] -> 7.5
function avgArray (arr) {
  return arr.reduce((n, sum) => n + sum, 0) / arr.length
}

/*  [
      { id: A1 },
      { id: A2 }
    ] =>
    {
      A1: {id: A1 },
      A2: {id: A2 }
    }
*/
function indexArray (arr, indexedProp = 'id') {
  const dict = {}
  for (const item of arr) {
    const index = item[indexedProp]
    dict[index] = item
  }
  return dict
}

function validateEmail (email) {
  if (!email) {
    return false
  }
  if (typeof email !== 'string') {
    return false
  }
  const result = email.trim().match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  return !!result
}

function validatePhone (phone) {
  if (!phone || !phone.match) {
    return false
  }
  const result = phone.match(/^[\d\s()+-]+$/)
  return !!result
}

function isObject (obj) {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    return true
  }
  return false
}
function countChildArrays (input) {
  // Map/Array/Object of Arrays
  // add up the lengths of all Arrays that are direct descendants

  if (input instanceof Map) {
    return Array.from(input).reduce((total, group) => {
      let current = 0
      if (Array.isArray(group)) {
        current = group.length
      }
      return total + current
    }, 0)
  }

  if (Array.isArray(input)) {
    return input.reduce((total, group) => {
      let current = 0
      if (Array.isArray(group)) {
        current = group.length
      }
      return total + current
    }, 0)
  }

  if (isObject(input)) {
    return Object.values(input).reduce((total, group) => {
      let current = 0
      if (Array.isArray(group)) {
        current = group.length
      }
      return total + current
    }, 0)
  }

  return null
}

function getDeepKey (obj, deepKey) {
  const steps = deepKey.split('.')
  let level = obj
  for (const key of steps) {
    if (key in level) {
      level = level[key]
      continue
    }
    return undefined
  }

  return level
}

function sortObjectsInArray (originalArray, key) {
  const arr = [...originalArray]

  arr.sort((a, b) => {
    const aValue = getDeepKey(a, key)?.toLowerCase() || ''
    const bValue = getDeepKey(b, key)?.toLowerCase() || ''
    return aValue.toLowerCase().localeCompare(bValue.toLowerCase())
  })

  return arr
}

// This function takes in latitude and longitude of two location
// and returns the distance between them as the crow flies (in km)
function calcCrow (lat1, lon1, lat2, lon2) {
  var R = 6371 // km
  var dLat = toRad(lat2 - lat1)
  var dLon = toRad(lon2 - lon1)
  lat1 = toRad(lat1)
  lat2 = toRad(lat2)

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d
}

// Converts numeric degrees to radians
function toRad (Value) {
  return Value * Math.PI / 180
}

export {
  hexToRGB,
  kebabToCamelCase,
  avgArray,
  indexArray,
  validateEmail,
  validatePhone,
  isObject,
  countChildArrays,
  sortObjectsInArray,
  getDeepKey,
  calcCrow
}
