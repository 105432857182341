<template>
    <teleport to="#dialog-container">
        <div class="dialog-component" :class="classes" @click="backgroundClick">
            <div class="dialog-wrap">
                <div class="dialog-box" :class="{shaking}" tabindex="0" @keydown.esc.capture="cancelHandler" ref="dialog" @click.stop>
                    <IconButton icon="close" class="close-button" @click="cancelHandler"/>
                    <div class="title" v-if="!contentOnly">
                        <div class="title-custom" v-if="$slots.default">
                            <slot name="title"></slot>
                        </div>
                        <div class="title-text" v-else>
                            {{ title }}
                        </div>
                    </div>
                    <div class="content">
                        <div class="content-custom" v-if="$slots.default">
                            <slot name="default"></slot>
                        </div>
                        <div class="content-default" v-else>
                            {{ text }}
                        </div>
                    </div>
                    <div class="actions" v-if="!contentOnly">
                        <slot name="actions" v-if="$slots.actions"></slot>
                        <template v-else>
                            <OutlineButton v-if="cancelText" @click="cancelHandler" :text="cancelText" muted/>
                            <OutlineButton @click="confirmHandler" :text="confirmText" :muted="!darkConfirmButton" :dark="darkConfirmButton" :shadow="darkConfirmButton && !loading" :loading="loading"/>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </teleport>
</template>

<script>
// import DialogBox from '@/components/core/DialogBox.vue'
/*

Title can be prop or slot#title

<DialogBox v-if="showDialog"
    @close="showDialog = false"
    @confirm="confirmDemoDialog"
    @cancel="cancelDemoDialog"
    confirm-dark confirm-muted cancelable secondary
>
    <template #title>
        Bekreft flytting av handlekurv
    </template>
    <slot>
        Vi har litt rusk i maskineriet akkurat nå. Prøv igjen senere, eller ta kontakt om problemet vedvarer.
    </slot>
    <template #actions>
        Only use if implementing custom action buttons.
    </template>
</DialogBox>
*/

import IconButton from '@/components/core/buttons/IconButton.vue'
import OutlineButton from '@/components/buttons/OutlineButton.vue'

export default {
  name: 'DialogBox',
  emits: [
    'close', // use to remove from dom, fired after both confirm and cancel on exit animation finish
    'confirm', // btn click, event with callbacks
    'cancel', // btn click, to handle cancel specifically
    // use '@close' instead to remove from dom
    'confirm-complete', // outro animation done after success callback
    'cancel-complete' // outro animation done after cancellation
  ],
  data () {
    return {
      defaultConfirm: this.$t('dialog.default.confirm'),
      defaultCancel: this.$t('dialog.default.cancel'),
      mounted: false,
      closing: false,
      cancelling: false,
      shaking: false,
      loading: false
    }
  },
  props: {
    title: String, // ignored if using slot#title
    confirm: String, // button text
    cancel: String, // button text
    cancelable: Boolean, // shakes box if trying to click outside
    secondary: Boolean, // small title, align left, actions right XD: 100.4
    confirmMuted: Boolean, // force confirm button to be grey regardless of type
    confirmDark: Boolean,
    contentOnly: Boolean // no template, use default slot for everything
  },
  components: {
    OutlineButton,
    IconButton
  },
  computed: {
    type () {
      if (this.secondary) {
        return 'secondary'
      }
      if (this.contentOnly) {
        return 'content-only'
      }
      return 'regular'
    },
    confirmText () {
      return this.confirm || this.defaultConfirm
    },
    cancelText () {
      if (this.cancel) {
        return this.cancel
      }
      if (this.cancelable) {
        return this.defaultCancel
      }
      return ''
    },
    darkConfirmButton () {
      if (this.confirmMuted) {
        return false
      }
      if (this.confirmDark) {
        return true
      }
      if (this.secondary) {
        return true
      }
      return false
    },
    classes () {
      const classes = {
        mounted: this.mounted,
        closing: this.closing,
        cancelling: this.cancelling,
        [`type-${this.type}`]: true // .type-regular | .type-secondary
      }
      return classes
    }
  },
  methods: {
    cancelHandler () {
      if (this.loading || this.cancelling) {
        return
      }
      if (!this.cancelable && !this.cancel) {
        this.indicateCancelDisallowed()
        return
      }
      this.shaking = false
      this.closing = true
      this.cancelling = true
      this.$emit('cancel')
      setTimeout(() => {
        this.$emit('close')
        this.$emit('cancel-complete')
      }, 200)
    },
    confirmHandler () {
      if (this.cancelling || this.loading || this.closing) {
        return
      }
      this.shaking = false
      this.loading = true
      this.$emit('confirm', { success: this.successCallback, error: this.errorCallback })
    },
    confirmMount () {
      this.mounted = true
      if (this.$refs.dialog) {
        this.$refs.dialog.focus()
      }
    },
    backgroundClick () {
      this.cancelHandler()
    },
    indicateCancelDisallowed () {
      this.shake()
    },
    shake () {
      this.shaking = true
      setTimeout(() => { this.shaking = false }, 1000)
    },
    successCallback (message) {
      if (message) {
        this.$store.dispatch('notification', { text: message })
      }
      this.closing = true
      setTimeout(() => {
        this.$emit('close')
        this.$emit('confirm-complete')
      }, 200)
    },
    errorCallback (message) {
      if (message) {
        this.$store.dispatch('notification', { text: message, type: 'error' })
      }
      this.shake()
      this.loading = false
    }
  },
  mounted () {
    setTimeout(this.confirmMount, 20)
  }
}
</script>

<style lang="css" scoped>
.dialog-component {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: all;
    transition: all 0.5s ease;
    perspective: 1000px;
}
.dialog-box {
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    margin: 20px;
    padding: 40px 30px 30px 30px;
    outline: none;
    pointer-events: all;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
    border-radius: 8px;
}
.dialog-component.mounted {
    background-color: rgba(0,0,0,0.5);
}
.dialog-component.closing {
    transition: background-color 0.3s ease;
    background-color: transparent;
}

.dialog-wrap {
    transform: translate3d(0,100px,200px) rotateX(45deg);
    transition: transform 0.3s;
    transform-origin: center center;
}
.dialog-component.mounted .dialog-wrap {
    transform: rotate(0);
}
.dialog-component.closing .dialog-wrap {
    transition: transform 0.2s ease-in, opacity 0.1s ease-in 0.1s;
    transform: translate3d(0,-100px,-50px) rotateX(35deg);
    opacity: 0;
}
.dialog-component.cancelling .dialog-wrap {
    transition: transform 0.2s ease-in, opacity 0.1s ease-in 0.1s;
    transform: translate3d(0,100px,-50px) rotateX(35deg);
    opacity: 0;
}
.actions {
    display: flex;
    gap: 20px;
    margin-top: 30px;
    justify-content: center;
}

.type-regular .title {
    font-family: Rando;
    font-size: 24px;
    line-height: 30px;
    margin-top: 10px;
    color: #435152;
    margin-bottom: 20px;
}
.content {
    font-size: 16px;
    line-height: 24px;
}

.type-secondary .dialog-box {
    padding: 20px;
}
.type-secondary .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #0F0F0F;
    margin-bottom: 10px;
    text-align: left;
}
.type-secondary .content {
    text-align: left;
}
.type-secondary .actions {
    justify-content: flex-end;
}

.shaking {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.close-button {
  position: absolute;
  top: 35px;
  right: 35px;
}
</style>
