<template>
    <div class="language-picker">
        <div class="current">{{current}}</div>
    </div>

    <DialogBox v-if="showLanguageDialog && !noPopup" content-only cancelable
        @close="showLanguageDialog = false"
    >
        <LanguageDialog/>
    </DialogBox>
</template>

<script>
import DialogBox from '@/components/dialogs/DialogBox.vue'
import LanguageDialog from '@/components/dialogs/LanguageDialog.vue'

export default {
  name: 'LanguagePicker',
  data () {
    return {
      showLanguageDialog: false,
      unsubscribeFunction: null
    }
  },
  props: {
    'no-popup': Boolean
  },
  components: { DialogBox, LanguageDialog },
  computed: {
    current () {
      return this.$store.getters.localeLabel(this.$i18n.locale)
    }
  },
  methods: {
    openLanguagePicker () {
      this.showLanguageDialog = true
    }
  },
  created () {
    this.unsubscribeFunction = this.$store.subscribeAction(action => {
      if (action.type === 'openLanguagePicker') {
        this.openLanguagePicker()
      }
    })
  }
}
</script>

<style lang="css" scoped>
.current {
    line-height: 18px;
    padding: 3px 13px;
    background: #fff;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 5%);
    border-radius: 100px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    transition: all 0.27s;
}

.current::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 2.5px 0;
    border-color: currentColor transparent transparent;
    margin-left: 4px;
}
</style>
