<template>
  <div class="outline-button" :class="classes">
    <component v-if="leftIconComponent" :is="leftIconComponent" class="icon" :style="iconCSS"/>
    <img class="icon" v-if="hasLeftIcon" :src="require(`@/assets/${icon}`)" :style="iconCSS"/>

    <Spinner class="spinner" v-if="loading" :size="spinnerSize"/>
    <span class="label">{{text}}</span>

    <component v-if="iconComponent" :is="iconComponent" class="icon" :style="iconCSS"/>
    <img class="icon" v-if="hasRightIcon" :src="require(`@/assets/${icon}`)" :style="iconCSS"/>
  </div>
</template>

<script>
// import OutlineButton from "@/components/core/OutlineButton.vue";

import Spinner from '@/components/core/Spinner.vue'

export default {
  name: 'OutlineButton',
  props: {
    debug: Boolean,
    muted: Boolean,
    dark: Boolean,
    subtle: Boolean,
    text: String,
    icon: String, // filter.svg (in /assets)
    iconLeft: Boolean, // icon on the right by default
    wide: Boolean,
    thick: Boolean,
    flip: Boolean,
    hFlip: Boolean,
    vFlip: Boolean,
    highlighted: Boolean,
    leftIconComponent: String,
    iconComponent: String,
    iconSize: { type: Number, default: 20 },
    iconOffset: { type: Number, default: 0 },
    iconVerticalOffset: { type: Number, default: 0 },
    center: Boolean,
    disabled: Boolean,
    loading: Boolean,
    shadow: Boolean
  },
  components: { Spinner },
  computed: {
    iconCSS () {
      const css = {
        width: `${this.iconSize}px`,
        height: `${this.iconSize}px`,
        right: `${this.iconOffset}px`,
        top: `${this.iconVerticalOffset}px`
      }
      return css
    },
    hasLeftIcon () {
      if (this.icon && this.iconLeft) { return true }
      return false
    },
    hasRightIcon () {
      if (this.icon && !this.iconLeft) { return true }
      return false
    },
    classes () {
      const classes = {
        debug: this.debug,
        wide: this.wide,
        dark: this.dark,
        subtle: this.subtle,
        flip: this.flip,
        thick: this.thick,
        highlighted: this.highlighted,
        muted: this.muted,
        center: this.center,
        disabled: this.disabled || this.loading,
        loading: this.loading,
        shadow: this.shadow,
        'icon-left': this.hasLeftIcon || this.leftIconComponent,
        'icon-right': this.hasRightIcon || this.iconComponent,
        'h-flip': this.hFlip,
        'v-flip': this.vFlip,
        'no-text': !this.text
      }
      return classes
    },
    spinnerSize () {
      if (this.thick) {
        return 36
      }
      return 32
    }
  }
}
</script>

<style scoped>
.outline-button {
  position: relative;
  border: 1px solid #D7DDDD;
  color:  #435152;
  line-height: 24px;
  height: 36px;
  font-size: 16px;
  letter-spacing: -0.2px;
  font-weight: 500;
  display: inline-flex;
  padding: 8px 16px;
  border-radius: 36px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.12s, background-color 0.12s;
}
.outline-button:hover {
  border: 1px solid #8C9A98;
  background-color: #fafafa;
}
.outline-button:active {
  background-color: rgba(140,154,152,0.1);
}

.outline-button.highlighted {
  border: 1px solid #E77423;
  color:  #E77423;
}
.outline-button.highlighted:hover {
  border: 1px solid #E77423;
}
.outline-button.highlighted:active {
  background-color: rgb(231, 116, 35, .1);
}

.label {
  font-family: "Circular Std";
  font-size: 16px;
  white-space: nowrap;
  opacity: 1;
}

.wide {
  width: 100%;
}
.wide .label {
  flex: 1;
  text-align: left;
}

.icon {
  transition: transform 0.2s;
  position: relative;
}

.icon-left .label {
  margin-left: 8px;
}
.icon-right .label {
  margin-right: 8px;
}

.flip .icon {
  transform: rotate(180deg);
}
.v-flip .icon {
  transform: scaleY(-1);
}
.h-flip .icon {
  transform: scaleX(-1);
}

.no-text .label {
  display: none;
}

.thick {
  height: 44px;
}
.center .label {
  text-align: center;
}

.muted {
  color: #435152;
  border-color: #D7DDDD;
  background-color: #F5F5F5;
}
.muted:hover {
  color: #3A494A;
  border-color: #8C9A98;
  background-color: #e8e8e8;
}
.muted:active {
  color: #3A494A;
  border-color: #8C9A98;
  background-color: #E1E1E1;
}

.dark {
  background-color: #435152;
  color: #F7F9F9;
}
.dark:hover {
  background-color: #566f71;
}

.subtle {
  border-color: #D7DDDD;
}

.dark.subtle {
  border-color: rgba(247, 249, 249, 0.25);
}

.dark.disabled {
    background-color: rgba(247, 249, 249, 0.25);
}

.disabled {
  background-color: rgba(0,0,0,0.05);
  border: 0;
  pointer-events: none;
  color:  #435152;
}
.disabled .label {
  opacity: 0.5;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.loading .label {
  opacity: 0;
}

.shadow {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16) !important;
}
</style>
