import { createI18n } from 'vue-i18n'
import nb from './locales/nb.json'
import sv from './locales/sv.json'
import fi from './locales/fi.json'
import en from './locales/en.json'

function fromBrowserLocale () {
  // navigator.locales = ['en-GB', 'en-US', 'en']
  const locales = {
    nb: ['no', 'nb', 'nn'],
    sv: ['sv'],
    fi: ['fi'],
    en: ['en']
  }
  for (const file in locales) {
    if (navigator.languages.includes(file)) {
      return file
    }
    const group = locales[file]
    for (const dialect of group) {
      if (navigator.languages.includes(dialect)) {
        return file
      }
    }
  }
}

function determineLocale () {
  const savedLocale = window.localStorage.getItem('locale')
  if (savedLocale) {
    return savedLocale
  }
  const locale = fromBrowserLocale()
  if (locale) {
    window.localStorage.setItem('locale', locale)
    return locale
  }

  return 'nb'
}

const i18n = createI18n({
  locale: determineLocale(),
  messages: {
    nb,
    sv,
    fi,
    en
  }
})

export default i18n
