<template>
    <div class="icon">
        <Email v-if="icon === 'email'"/>
        <Phone v-if="icon === 'phone'"/>
        <Arrow v-if="icon === 'arrow'"/>
        <UserOutline v-if="icon === 'user-outline'"/>
        <Search v-if="icon === 'search'"/>
        <Facebook v-if="icon === 'facebook'"/>
        <Instagram v-if="icon === 'instagram'"/>
        <Close v-if="icon === 'close'"/>
        <Hamburger v-if="icon === 'hamburger'"/>
        <Location v-if="icon === 'location'"/>
        <Pin v-if="icon === 'pin'"/>
    </div>
</template>

<script>
 // import Icon from '@/components/core/Icon.vue'

 import Email from '@/components/core/icons/Email.vue'
 import Arrow from '@/components/core/icons/Arrow.vue'
 import Phone from '@/components/core/icons/Phone.vue'
 import UserOutline from '@/components/core/icons/UserOutline.vue'
 import Search from '@/components/core/icons/Search.vue'
 import Facebook from '@/components/core/icons/Facebook.vue'
 import Instagram from '@/components/core/icons/Instagram.vue'
 import Close from '@/components/core/icons/Close.vue'
 import Hamburger from '@/components/core/icons/Hamburger.vue'
 import Location from '@/components/core/icons/Location.vue'
 import Pin from '@/components/core/icons/Pin.vue'

 export default {
     name: 'Icon',
     props: {
         icon: { type: String, default: 'user-outline' }
     },
     components: {
         Email,
         Arrow,
         Phone,
         UserOutline,
         Search,
         Facebook,
         Instagram,
         Close,
         Hamburger,
         Location,
         Pin
     }
 }
</script>

<style lang="css" scoped>
.icon {
    width: 100%;
    height: 100%;
}
.icon >>> svg {
    width: 100%;
    height: 100%;
}
</style>
