import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/FindDealer.vue')

  },

  {
    path: '/find/:searchMode/:focusedItemId?',
    name: 'FindDealer',
    component: () => import(/* webpackChunkName: "find" */ '@/views/FindDealer.vue'),
    props: true
  },

  {
    path: '/profile/:id',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue'),
    props: true

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // ...
  // explicitly return false to cancel the navigation
  if (to.query.lang) {
    window.i18n.locale = to.query.lang
    window.localStorage.setItem('locale', to.query.lang)
    if (window.i18n.locale !== to.query.lang) {
      next(to)
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
